.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #8a2b06;
  padding: 1rem 0;
  margin: 1rem 0;
  max-height: 20rem;
}

.cart-item h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.cart-item__summary {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item__price {
  font-weight: bold;
  color: #8a2b06;
}

.cart-item__amount {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  color: #363636;
  margin: 10px;
}

.cart-item__actions {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .cart-item__actions {
    flex-direction: row;
  }
}

.cart-item button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: #8a2b06;
  border: 1px solid #8a2b06;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.cart-item button:hover,
.cart-item button:active {
  background-color: #8a2b06;
  color: white;
}